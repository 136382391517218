






























































































































































































































































































 /deep/.el-input-group__prepend {
    padding: 0 20px 0 0;
    border:none !important;

}
.btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.casStyle {
    width: 300px;
}
